import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { TileTextBlock } from "shared/ui";
import "./styles.scss";

export const ComingSoonTile: FC = () => (
  <div className="soon__tile">
    <TileTextBlock
      className="soon__tile-text__block"
      pointKey="functionalCapabilities.comingSoon.point"
      titleKey="functionalCapabilities.comingSoon.title"
      descriptionKey="functionalCapabilities.comingSoon.description"
    />
    <StaticImage
      src="./assets/comingSoonMobile.webp"
      alt="crypto-balance-view"
      className="soon__tile-image__mobile"
    />
    <StaticImage
      src="./assets/comingSoonDesktop.webp"
      alt="crypto-balance-view"
      className="soon__tile-image__desktop"
    />
  </div>
);
