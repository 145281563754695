import React, { FC } from "react";
import { MultiCurrencyFeature } from "../MultiCurrencyFeature";
import { ScoringFeature } from "../ScoringFeature";
import { GasFeature } from "../GasFeature";
import "./styles.scss";
import { Web3Feature } from "../Web3Feature";

export const DesktopFeatures: FC = () => (
  <div className="desktop__features">
    <MultiCurrencyFeature />
    <ScoringFeature />
    <GasFeature />
    <Web3Feature />
  </div>
);
