import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Point } from "shared/ui";
import "./styles.scss";
import { FeaturesSlider } from "../FeaturesSlider";
import { DesktopFeatures } from "../DesktopFeatures";

export const ExploreFeatures: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="explore__features" id="features">
      <Point pointKey="features.point" type="gradient" />
      <h2 className="explore__features-title">{t("features.title")}</h2>
      <FeaturesSlider />
      <DesktopFeatures />
    </div>
  );
};
