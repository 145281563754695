import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IFeatureTextBlock } from "./types";
import "./styles.scss";

export const FeatureTextBlock: FC<IFeatureTextBlock> = ({
  titleKey,
  descriptionKey,
  type = "primary",
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <div className={`feature__text ${className}`}>
      <h3 data-type={type} className="feature__title">
        {t(titleKey)}
      </h3>
      <p data-type={type} className="regular-text feature__description">
        {t(descriptionKey)}
      </p>
    </div>
  );
};
