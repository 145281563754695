import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FeedbackContext } from "../../../../context";
import {
  EMAIL_MAX_LENGTH,
  COMMENT_MAX_LENGTH,
  REGION_MAX_LENGTH,
  FULL_NAME_MAX_LENGTH,
  emailRegex,
} from "shared/config";
import { useSendEmail } from "shared/lib";

export const useFeedbackForm = () => {
  const { t } = useTranslation();
  const { toggleIsOpenModal } = useContext(FeedbackContext);
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [region, setRegion] = useState("");
  const [regionError, setRegionError] = useState("");
  const [comments, setComments] = useState("");
  const [commentsError, setCommentsError] = useState("");
  const { isPending, isSuccess, sendEmail, resetSendEmail } = useSendEmail();

  const changeEmail = (email: string) => {
    if (!email) setEmailError(t("validation.required"));
    else if (!emailRegex.test(email)) setEmailError(t("validation.email"));
    else if (email.length > EMAIL_MAX_LENGTH)
      setEmailError(
        t("validation.maxLength", {
          count: EMAIL_MAX_LENGTH,
        })
      );
    else setEmailError("");

    setEmailValue(email);
  };

  const changeFullName = (fullName: string) => {
    if (!fullName) setFullNameError(t("validation.required"));
    else if (fullName.length > FULL_NAME_MAX_LENGTH)
      setFullNameError(
        t("validation.maxLength", {
          count: FULL_NAME_MAX_LENGTH,
        })
      );
    else setFullNameError("");
    setFullName(fullName);
  };

  const changeRegion = (region: string) => {
    if (region.length > REGION_MAX_LENGTH)
      setRegionError(
        t("validation.maxLength", {
          count: REGION_MAX_LENGTH,
        })
      );
    else setRegionError("");
    setRegion(region);
  };

  const changeComments = (comments: string) => {
    if (comments.length > COMMENT_MAX_LENGTH)
      setCommentsError(
        t("validation.maxLength", {
          count: COMMENT_MAX_LENGTH,
        })
      );
    else setCommentsError("");
    setComments(comments);
  };

  const submitForm = async () => {
    if (!isSuccess) {
      let isValid = true;
      if (!emailValue) {
        setEmailError(t("validation.required"));
        isValid = false;
      }

      if (!fullName) {
        setFullNameError(t("validation.required"));
        isValid = false;
      }

      if (commentsError || regionError) isValid = false;

      if (isValid) {
        sendEmail({
          email: emailValue,
          name: fullName,
          region: region || undefined,
          comment: comments || undefined,
        });
      }
      return;
    }

    toggleIsOpenModal();
  };

  const resetForm = () => {
    setEmailValue("");
    setEmailError("");
    setFullName("");
    setFullNameError("");
    setRegion("");
    setComments("");
    resetSendEmail();
  };

  return {
    emailValue,
    emailError,
    changeEmail,
    fullName,
    fullNameError,
    changeFullName,
    region,
    regionError,
    changeRegion,
    comments,
    commentsError,
    changeComments,
    submitForm,
    isPending,
    resetForm,
    isValid: Boolean(
      !emailError && !fullNameError && !commentsError && !regionError
    ),
    isSuccess,
  };
};
