import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input } from "shared/ui";
import "./styles.scss";
import { ReactComponent as Solution } from "./assets/solution.svg";
import { FeedbackContext } from "../../context";

export const LearnMoreForm: FC = () => {
  const { t } = useTranslation();
  const { email, changeEmail, error, joinToWaitList, isPending } =
    useContext(FeedbackContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    joinToWaitList();
  };

  return (
    <form onSubmit={handleSubmit} className="learn__form">
      <h2 className="learn__form-title">{t("learnMore.title")}</h2>
      <div className="learn__form-solution__container">
        <Solution className="learn__form-solution__border" />
        <h2 className="learn__form-solution">{t("learnMore.solution")}</h2>
      </div>
      <p className="regular-text learn__form-description">
        {t("learnMore.description")}
      </p>
      <Input
        className="learn__form-input__container"
        value={email}
        disabled={isPending}
        onChange={changeEmail}
        placeholder={t("learnMore.email")}
        errorMessage={error}
        rightButton={
          <Button
            type="submit"
            disabled={Boolean(error)}
            isLoading={isPending}
            className="learn__form-input__button"
          >
            {t("mainBanner.joinButton")}
          </Button>
        }
      />
    </form>
  );
};
