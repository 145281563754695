import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Point } from "../Point";
import "./styles.scss";
import { ITileTextBlock } from "./types";

export const TileTextBlock: FC<ITileTextBlock> = ({
  type = "primary",
  titleKey,
  pointKey,
  descriptionKey,
  className = "",
}) => {
  const { t } = useTranslation();
  return (
    <div className={`tile__text ${className}`}>
      <Point pointKey={pointKey} type={type} />
      <h3 className="tile__text-title" data-type={type}>
        {t(titleKey)}
      </h3>
      <p className="regular-text tile__text-description" data-type={type}>
        {t(descriptionKey)}
      </p>
    </div>
  );
};
