import React, { FC } from "react";
import { FeatureTextBlock } from "shared/ui";
import { StaticImage } from "gatsby-plugin-image";
import "./styles.scss";

export const Web3Feature: FC = () => (
  <div className="web3__feature">
    <FeatureTextBlock
      className="web3__feature-text__block"
      titleKey="features.tabs.web3.title"
      descriptionKey="features.tabs.web3.description"
    />
    <StaticImage
      src="./assets/web3mobile.webp"
      alt="web3"
      className="web3__feature-image__mobile"
    />
    <StaticImage
      src="./assets/web3smallTablet.webp"
      alt="web3"
      className="web3__feature-image__smallTablet"
    />
    <StaticImage
      src="./assets/web3tablet.webp"
      alt="web3"
      className="web3__feature-image__tablet"
    />
    <StaticImage
      src="./assets/web3desktop.webp"
      alt="web3"
      className="web3__feature-image__desktop"
    />
    <StaticImage
      src="./assets/web3largeDesktop.webp"
      alt="web3"
      className="web3__feature-image__largeDesktop"
    />
  </div>
);
