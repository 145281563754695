import React, { FC } from "react";
import "./styles.scss";
import { LearnMoreForm } from "../LearnMoreForm";
import { StaticImage } from "gatsby-plugin-image";

export const LearnMore: FC = () => {
  return (
    <div className="learn__more" id="learnMore">
      <LearnMoreForm />
      <div className="learn__more-image__container">
        <StaticImage
          className="learn__more-image"
          src="./assets/learnMore.webp"
          alt="application-view"
        />
      </div>
    </div>
  );
};
