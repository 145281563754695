import React, { FC, useContext, useLayoutEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FeedbackContext } from "../../context";
import { useFeedbackForm } from "./lib";
import { ReactComponent as Cross } from "shared/assets/svg/crossIcon.svg";
import { Button } from "shared/ui";
import "./styles.scss";
import { FeedbackForm } from "../FeedbackForm";
import { FeedbackFormSuccess } from "../FeedbackFormSuccess";

let timeout: number | null = null;

export const FeedbackFormModal: FC = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { email, dropEmail, toggleIsOpenModal, isOpenModal, error } =
    useContext(FeedbackContext);
  const { resetForm, isValid, isSuccess, ...form } = useFeedbackForm();

  useLayoutEffect(() => {
    const body = document.body;
    if (isOpenModal) {
      ref.current.classList.add("feedback__overlay-open");
      body.classList.add("no-overflow__modal");
      if (email && !error) {
        form.changeEmail(email);
      }
      dropEmail();
    } else {
      timeout = setTimeout(() => {
        ref.current.classList.remove("feedback__overlay-open");
        body.classList.remove("no-overflow__modal");
        timeout = null;
        resetForm();
      }, 350);
    }
  }, [isOpenModal]);

  const handleOverlayClick = (e) => {
    if (e.target === ref.current && !timeout && !form.isPending) {
      toggleIsOpenModal();
    }
  };

  const handleSubmitAfterSend = () => {
    if (isSuccess) toggleIsOpenModal();
  };

  return (
    <div
      onClick={handleOverlayClick}
      ref={ref}
      className="feedback__overlay"
      data-is-open={isOpenModal}
    >
      <div className="feedback__modal">
        <div className="feedback__modal-header">
          <h3 className="feedback__modal-title">
            <Trans i18nKey="feedbackModal.title" components={[<span />]} />
          </h3>
          <Button
            disabled={form.isPending}
            className="feedback__modal-close__button"
            onClick={toggleIsOpenModal}
            variant="secondary"
          >
            <Cross className="feedback__modal-cross" />
          </Button>
        </div>
        {isSuccess ? <FeedbackFormSuccess /> : <FeedbackForm {...form} />}
        <Button
          form="feedbackForm"
          type={isSuccess ? "button" : "submit"}
          disabled={!isValid}
          isLoading={form.isPending}
          onClick={handleSubmitAfterSend}
          className="feedback__modal-submit__button"
        >
          {t("feedbackModal.submitButton")}
        </Button>
      </div>
    </div>
  );
};
