import React, { FC } from "react";
import { FeatureTextBlock } from "shared/ui";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";

export const MultiCurrencyFeature: FC = () => (
  <div className="currency__feature">
    <FeatureTextBlock
      className="currency__feature-text__block"
      titleKey="features.tabs.multiCurrency.title"
      descriptionKey="features.tabs.multiCurrency.description"
    />
    <StaticImage
      src="./assets/multiCurrencyMobile.webp"
      alt="coin"
      className="currency__feature-image__mobile"
    />
    <StaticImage
      src="./assets/multiCurrencySmallTablet.webp"
      alt="coin"
      className="currency__feature-image__smallTablet"
    />
    <StaticImage
      src="./assets/multiCurrencyTablet.webp"
      alt="coin"
      className="currency__feature-image__tablet"
    />
    <StaticImage
      src="./assets/multiCurrencyDesktop.webp"
      alt="coin"
      className="currency__feature-image__desktop"
    />
    <StaticImage
      src="./assets/multiCurrencyLargeDesktop.webp"
      alt="coin"
      className="currency__feature-image__largeDesktop"
    />
  </div>
);
