import { useState } from "react";
import { ISendEmail } from "./types";

export const useSendEmail = () => {
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const resetSendEmail = () => {
    setIsPending(false);
    setIsSuccess(false);
  };

  const sendEmail = async ({ email, name, region, comment }: ISendEmail) => {
    setIsPending(true);

    try {
      await fetch("https://mail-sender-index.ilink.dev/sendmail", {
        method: "POST",
        mode: "no-cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          email,
          name,
          region,
          comment,
        }),
      });

      setIsSuccess(true);
    } catch (e) {
      console.log("ERROR", e);
    }
    setIsPending(false);
  };

  return {
    isPending,
    isSuccess,
    sendEmail,
    resetSendEmail,
  };
};
