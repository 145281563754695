import React, { FC } from "react";
import Carousel from "nuka-carousel";
import { MultiCurrencyFeature } from "../MultiCurrencyFeature";
import { ScoringFeature } from "../ScoringFeature";
import { GasFeature } from "../GasFeature";
import { Web3Feature } from "../Web3Feature";
import "./styles.scss";

export const FeaturesSlider: FC = () => {
  return (
    <div className="features-slider__wrapper">
      <Carousel
        className="features__slider"
        defaultControlsConfig={{
          nextButtonStyle: { display: "none" },
          prevButtonStyle: { display: "none" },
          pagingDotsContainerClassName: "slider__pagination",
        }}
        cellSpacing={24}
      >
        <MultiCurrencyFeature />
        <div className="custom-slide">
          <ScoringFeature />
          <GasFeature />
        </div>
        <Web3Feature />
      </Carousel>
    </div>
  );
};
