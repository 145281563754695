import React, { FC } from "react";
import "shared/locales/i18n";
import "./styles.scss";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { IPageTemplate } from "./types";
import { FeedbackFormModal } from "../FeedbackFormModal";

export const PageTemplate: FC<IPageTemplate> = ({ children, pathname }) => {
  return (
    <>
      <div className="main">
        <div className="main__wrapper">
          <main className="main__container">
            <Header pathname={pathname} />
            <div className="main__body">{children}</div>
            <Footer pathname={pathname} />
          </main>
        </div>
      </div>
      <FeedbackFormModal />
    </>
  );
};
