import React, { FC } from "react";
import { FeatureTextBlock } from "shared/ui";
import "./styles.scss";

export const ScoringFeature: FC = () => (
  <div className="scoring__feature">
    <FeatureTextBlock
      type="secondary"
      titleKey="features.tabs.scoring.title"
      descriptionKey="features.tabs.scoring.description"
    />
  </div>
);
