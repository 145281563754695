import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Point } from "shared/ui";
import "./styles.scss";
import { ExchangeCryptoTiles } from "../ExchangeCryptoTiles";
import video from "./assets/exchangeCryptoAnimation.mp4";

export const ExchangeCrypto: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="exchange__wrapper">
      <div className="exchange__crypto">
        <div className="exchange__crypto-info__wrapper">
          <div className="exchange__crypto-text__wrapper">
            <Point pointKey="exchangeCrypto.point" type="gradient" />
            <h2 className="exchange__crypto-title">
              <Trans
                i18nKey="exchangeCrypto.title"
                components={[<span />, <span />]}
              />
            </h2>
            <p className="regular-text exchange__crypto-description">
              {t("exchangeCrypto.description")}
            </p>
          </div>
          <div className="exchange__crypto-video__wrapper">
            <video
              src={video}
              loop
              autoPlay
              muted
              playsInline
              className="exchange__crypto-video"
            />
          </div>
        </div>
        <ExchangeCryptoTiles />
      </div>
    </div>
  );
};
