import React, { FC, useState } from "react";
import { ReactComponent as Cross } from "shared/assets/svg/crossIcon.svg";
import "./styles.scss";
import { IQuestion } from "./types";
import { Button } from "../Button";

export const Question: FC<IQuestion> = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <div className="question">
      <div className="question-header">
        <h4 className="question-title">{title}</h4>
        <Button onClick={toggleIsOpen} className="question-button">
          <Cross
            className={`question-cross ${isOpen ? "question-cross__open" : ""}`}
          />
        </Button>
      </div>
      <div
        className="question-description__outer_container"
        data-is-open={isOpen}
      >
        <div className="question-description__inner-container">
          <p className="regular-text question-description">{description}</p>
        </div>
      </div>
    </div>
  );
};
