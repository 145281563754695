import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation, Trans } from "react-i18next";
import { Button } from "shared/ui";
import "./styles.scss";
import { navigate } from "gatsby";
import { ERoutes } from "shared/config";

export const ThankYouBanner: FC = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(ERoutes.default);
  };

  return (
    <div className="thanks__banner">
      <div className="thanks__banner-content">
        <h1 className="thanks__banner-title">
          <Trans i18nKey="thanksBanner.title" components={[<span />]} />
        </h1>
        <p className="regular-text thanks__banner-description">
          {t("thanksBanner.description")}
        </p>
        <Button onClick={handleClick} className="thanks__banner-button">
          {t("thanksBanner.goToMainButton")}
        </Button>
      </div>
      <StaticImage
        className="thanks__banner-image"
        src="./assets/thankYouImage.webp"
        alt="check-image"
      />
    </div>
  );
};
