import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import { IHeader } from "./types";
import { ERoutes } from "shared/config";
import { headerLinks } from "./config";
import { ReactComponent as Logo } from "shared/assets/svg/logo.svg";
import { Button } from "shared/ui";
import "./styles.scss";

export const Header: FC<IHeader> = ({ pathname }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sectionId, setSectionId] = useState<string>("");
  const { t } = useTranslation();
  const isVisibleJoinButton = pathname === ERoutes.default;

  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.classList.add("no-overflow");
      return;
    }

    body.classList.remove("no-overflow");
  }, [isOpen]);

  useEffect(() => {
    if (sectionId && pathname === ERoutes.default) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
        });
      }
      setSectionId("");
    }
  }, [pathname]);

  const handleToggleDropdownHeader = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDropdownHeader = () => {
    if (isOpen) setIsOpen(false);
  };

  const handleDesktopLinkClick = async (linkTo: string) => {
    if (pathname !== ERoutes.default) {
      navigate(ERoutes.default);
      setSectionId(linkTo);
      return;
    }
    const section = document.getElementById(linkTo);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleMobileLinkClick = (linkTo: string) => {
    handleDesktopLinkClick(linkTo);
    closeDropdownHeader();
  };

  const handleJoinClick = () => {
    handleDesktopLinkClick("learnMore");
    closeDropdownHeader();
  };

  const handleLogoClick = () => {
    if (pathname !== ERoutes.default) {
      navigate(ERoutes.default);
      return;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header className="header">
      <div className="header__content">
        <div className="header__container">
          <button className="header__logo-button" onClick={handleLogoClick}>
            <Logo className="header__logo" />
          </button>

          <nav className="header__header-nav--desktop">
            <ul className="header__header-list menu-text">
              {headerLinks.map(({ title, linkTo }) => (
                <li
                  onClick={() => handleDesktopLinkClick(linkTo)}
                  className="header__header-item"
                  key={title}
                >
                  {title}
                </li>
              ))}
            </ul>
          </nav>

          <div className="header__right-content">
            <div
              className="header__right-content--button"
              data-is-visible-join={isVisibleJoinButton}
            >
              <Button
                onClick={handleJoinClick}
                className="header__small-button"
                variant="gradient"
              >
                {t("header.waitList")}
              </Button>
            </div>
            <div
              className="header__burger"
              onClick={handleToggleDropdownHeader}
            >
              <p className="header__burger--top" />
              <p className="header__burger--middle" />
              <p className="header__burger--bottom" />
            </div>
          </div>
        </div>
        <nav className="header__header-nav" data-is-open={isOpen}>
          <div className="header__nav-container">
            <ul className="header__header-list menu-text">
              {headerLinks.map(({ title, linkTo }) => (
                <li
                  onClick={() => handleMobileLinkClick(linkTo)}
                  className="header__header-item"
                  data-is-visible-join={isVisibleJoinButton}
                  key={title}
                >
                  {title}
                </li>
              ))}
            </ul>
            {isVisibleJoinButton && (
              <div className="header__button-join">
                <Button
                  onClick={handleJoinClick}
                  variant="gradient"
                  className="header__button-join--button"
                >
                  {t("header.waitList")}
                </Button>
              </div>
            )}
          </div>
        </nav>
      </div>
      <div
        className="overlay"
        data-is-open={isOpen}
        onClick={closeDropdownHeader}
        role="presentation"
      />
    </header>
  );
};
