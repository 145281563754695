import React, { FC } from "react";
import Carousel from "nuka-carousel";
import { KycTile } from "../KycTile";
import { SecureTile } from "../SecureTile";
import { SupportTile } from "../SupportTile";
import { PrivacyTile } from "../PrivacyTile";
import { MultiAccountTile } from "../MultiAccountTile";
import { ComingSoonTile } from "../ComingSoonTile";
import "./styles.scss";

export const FunctionalTilesSlider: FC = () => {
  return (
    <div className="tiles-slider__wrapper">
      <Carousel
        className="tiles__slider"
        defaultControlsConfig={{
          nextButtonStyle: { display: "none" },
          prevButtonStyle: { display: "none" },
          pagingDotsContainerClassName: "slider__pagination",
        }}
        cellSpacing={24}
      >
        <KycTile />
        <SecureTile />
        <div className="custom-slide">
          <SupportTile />
          <PrivacyTile />
        </div>
        <MultiAccountTile />
        <ComingSoonTile />
      </Carousel>
    </div>
  );
};
