import React, { FC } from "react";
import { FeatureTextBlock } from "shared/ui";
import { StaticImage } from "gatsby-plugin-image";
import "./styles.scss";

export const GasFeature: FC = () => (
  <div className="gas__feature">
    <FeatureTextBlock
      type="secondary"
      titleKey="features.tabs.gas.title"
      descriptionKey="features.tabs.gas.description"
    />
    <StaticImage
      src="./assets/gas.webp"
      alt="gas"
      className="gas__feature-image"
    />
  </div>
);
