import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { TileTextBlock } from "shared/ui";
import "./styles.scss";

export const MultiAccountTile: FC = () => (
  <div className="multi__tile">
    <TileTextBlock
      className="multi__tile-text__block"
      type="secondary"
      pointKey="functionalCapabilities.multiAccount.point"
      titleKey="functionalCapabilities.multiAccount.title"
      descriptionKey="functionalCapabilities.multiAccount.description"
    />
    <StaticImage
      src="./assets/multiAccount.webp"
      alt="multi-view"
      className="multi__tile-image"
    />
  </div>
);
