import React, { FC } from "react";
import { TileTextBlock } from "shared/ui";
import "./styles.scss";

export const PrivacyTile: FC = () => (
  <div className="privacy__tile">
    <TileTextBlock
      type="secondary"
      pointKey="functionalCapabilities.privacy.point"
      titleKey="functionalCapabilities.privacy.title"
      descriptionKey="functionalCapabilities.privacy.description"
    />
  </div>
);
