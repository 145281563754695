import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { TileTextBlock } from "shared/ui";
import "./styles.scss";

export const SecureTile: FC = () => (
  <div className="secure__tile">
    <TileTextBlock
      type="secondary"
      className="secure__tile-text__block"
      pointKey="functionalCapabilities.secure.point"
      titleKey="functionalCapabilities.secure.title"
      descriptionKey="functionalCapabilities.secure.description"
    />
    <StaticImage
      src="./assets/secureMobile.webp"
      alt="secure-view"
      className="secure__tile-image__mobile"
    />
    <StaticImage
      src="./assets/secureSmallTablet.webp"
      alt="secure-view"
      className="secure__tile-image__smallTablet"
    />
    <StaticImage
      src="./assets/secureTablet.webp"
      alt="secure-view"
      className="secure__tile-image__tablet"
    />
    <StaticImage
      src="./assets/secureDesktop.webp"
      alt="secure-view"
      className="secure__tile-image__desktop"
    />
  </div>
);
