import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ITab } from "./types";
import "./styles.scss";

export const Tab: FC<ITab> = ({
  id,
  currentId,
  labelKey,
  onClick,
  className = "",
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick(id);
  };

  return (
    <button
      onClick={handleClick}
      className={`tab tab-font ${className}`}
      data-is-active={id === currentId}
    >
      {t(labelKey)}
    </button>
  );
};
