import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { Point } from "shared/ui";
import { FunctionalDesktopTiles } from "../FunctionalDesktopTiles";
import { FunctionalTilesSlider } from "../FunctionalTilesSlider";

export const FunctionalCapabilities: FC = () => {
  const { t } = useTranslation();

  return (
    <div id="functionalCapabilities">
      <Point
        className="functional__capabilities-point"
        pointKey="functionalCapabilities.point"
        type="gradient"
      />
      <h2 className="functional__capabilities-title">
        {t("functionalCapabilities.title")}
      </h2>
      <FunctionalTilesSlider />
      <FunctionalDesktopTiles />
    </div>
  );
};
