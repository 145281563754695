import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { TileTextBlock } from "shared/ui";
import "./styles.scss";

export const KycTile: FC = () => (
  <div className="kyc__tile">
    <TileTextBlock
      pointKey="functionalCapabilities.kyc.point"
      titleKey="functionalCapabilities.kyc.title"
      descriptionKey="functionalCapabilities.kyc.description"
    />
    <StaticImage
      src="./assets/kycSmall.webp"
      alt="kyc-view"
      className="kyc__tile-image__small"
    />
    <StaticImage
      src="./assets/kycBig.webp"
      alt="kyc-view"
      className="kyc__tile-image__big"
    />
  </div>
);
