import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export const FeedbackFormSuccess: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="feedback__sent">
      <StaticImage
        className="feedback__sent-image"
        src="./assets/success.webp"
        alt="check-icon"
        objectFit="contain"
      />

      <p className="regular-text feedback__sent-description">
        {t("feedbackModal.success")}
      </p>
    </div>
  );
};
