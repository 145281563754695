import React, { FC } from "react";
import { ReactComponent as PerformanceIcon } from "./assets/performanceIcon.svg";
import { ReactComponent as PrivacyIcon } from "./assets/privacyIcon.svg";
import { ReactComponent as ScaleIcon } from "./assets/scaleIcon.svg";
import { ExchangeCryptoTile } from "../ExchangeCryptoTile";
import "./styles.scss";

export const ExchangeCryptoTiles: FC = () => {
  return (
    <div className="tiles__wrapper">
      <ExchangeCryptoTile
        Icon={<PrivacyIcon />}
        titleKey="exchangeCrypto.tabs.privacy.title"
        descriptionKey="exchangeCrypto.tabs.privacy.description"
        className="tiles__wrapper-privacy__tile"
        textClassName="tiles__wrapper-privacy__text"
      />
      <ExchangeCryptoTile
        Icon={<ScaleIcon />}
        titleKey="exchangeCrypto.tabs.scale.title"
        descriptionKey="exchangeCrypto.tabs.scale.description"
        className="tiles__wrapper-scale__tile"
        textClassName="tiles__wrapper-scale__text"
      />
      <ExchangeCryptoTile
        Icon={<PerformanceIcon />}
        titleKey="exchangeCrypto.tabs.performance.title"
        descriptionKey="exchangeCrypto.tabs.performance.description"
        className="tiles__wrapper-performance__tile"
        textClassName="tiles__wrapper-performance__text"
      />
    </div>
  );
};
