import React, { FC } from "react";
import { KycTile } from "../KycTile";
import { SecureTile } from "../SecureTile";
import { SupportTile } from "../SupportTile";
import { PrivacyTile } from "../PrivacyTile";
import { MultiAccountTile } from "../MultiAccountTile";
import { ComingSoonTile } from "../ComingSoonTile";
import "./styles.scss";

export const FunctionalDesktopTiles: FC = () => (
  <div className="desktop__tiles">
    <KycTile />
    <SecureTile />
    <SupportTile />
    <PrivacyTile />
    <MultiAccountTile />
    <ComingSoonTile />
  </div>
);
