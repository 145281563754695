import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IExchangeCryptoTile } from "./types";
import "./styles.scss";

export const ExchangeCryptoTile: FC<IExchangeCryptoTile> = ({
  Icon,
  titleKey,
  descriptionKey,
  className = "",
  textClassName = "",
}) => {
  const { t } = useTranslation();

  return (
    <div className={`crypto__tile ${className}`}>
      {Icon}
      <h3 className={textClassName}>{t(titleKey)}</h3>
      <p className={`regular-text ${textClassName}`}>{t(descriptionKey)}</p>
    </div>
  );
};
