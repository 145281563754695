import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IFeedbackForm } from "./types";
import { Input } from "shared/ui";
import "./styles.scss";

export const FeedbackForm: FC<IFeedbackForm> = ({
  emailValue,
  emailError,
  changeEmail,
  fullName,
  fullNameError,
  changeFullName,
  region,
  regionError,
  changeRegion,
  comments,
  commentsError,
  changeComments,
  isPending,
  submitForm,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <form onSubmit={handleSubmit} id="feedbackForm" className="feedback__form">
      <Input
        disabled={isPending}
        isRequired
        value={emailValue}
        onChange={changeEmail}
        placeholder="michael@gmail.com"
        errorMessage={emailError}
        label={t("feedbackModal.form.emailLabel")}
      />
      <Input
        disabled={isPending}
        isRequired
        value={fullName}
        onChange={changeFullName}
        placeholder="Michael Brodsky"
        errorMessage={fullNameError}
        label={t("feedbackModal.form.fullNameLabel")}
      />
      <Input
        disabled={isPending}
        value={region}
        onChange={changeRegion}
        placeholder="Dubai"
        errorMessage={regionError}
        label={t("feedbackModal.form.stateLabel")}
      />
      <Input
        disabled={isPending}
        isTextarea
        value={comments}
        onChange={changeComments}
        placeholder="Hi 1ndex! I want to buy your product!"
        errorMessage={commentsError}
        label={t("feedbackModal.form.commentsLabel")}
      />
    </form>
  );
};
