import React, { FC, ChangeEvent } from "react";
import { IInput } from "./types";
import "./styles.scss";

export const Input: FC<IInput> = ({
  value,
  onChange,
  label = null,
  placeholder,
  disabled = false,
  errorMessage = null,
  rightButton,
  className = "",
  inputClassName = "",
  isRequired = false,
  isTextarea = false,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={`input ${className}`}>
      <div className="input__label-wrapper">
        {label && (
          <label data-is-required={isRequired} className="input__label">
            {label}
          </label>
        )}
        {errorMessage && <p className="input__error-message">{errorMessage}</p>}
      </div>
      <div className="input__wrapper">
        {isTextarea ? (
          <textarea
            value={value}
            onChange={handleChange}
            disabled={disabled}
            placeholder={placeholder}
            data-is-textarea
            className={`input__field ${inputClassName}`}
            data-is-error={Boolean(errorMessage)}
          />
        ) : (
          <>
            <input
              value={value}
              onChange={handleChange}
              disabled={disabled}
              placeholder={placeholder}
              className={`input__field ${inputClassName}`}
              data-is-error={Boolean(errorMessage)}
              data-with-right-button={Boolean(rightButton)}
            />
            {rightButton && (
              <div className="input__right-button">{rightButton}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
