import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "shared/assets/svg/logo.svg";
import { Button } from "shared/ui";
import { contacts, socialNetworks } from "./config";
import "./styles.scss";
import { FeedbackContext } from "../../context";
import { IFooter } from "./types";
import { ERoutes } from "shared/config";
import { navigate } from "gatsby";

export const Footer: FC<IFooter> = ({ pathname }) => {
  const { t } = useTranslation();
  const { toggleIsOpenModal } = useContext(FeedbackContext);

  const handleLogoClick = () => {
    if (pathname !== ERoutes) {
      navigate(ERoutes.default);
      return;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer" id="contacts">
      <div className="footer__main">
        <div className="footer__top">
          <button className="footer__logo-button" onClick={handleLogoClick}>
            <Logo className="footer__logo" />
          </button>
          <div className="footer__contacts footer__contacts--text">
            <a href={`tel:${contacts.phone}`} className="footer__contact-phone">
              {contacts.phone}
            </a>
            <a
              href={`mailto:${contacts.email}`}
              className="footer__contact-email"
            >
              {contacts.email}
            </a>
          </div>
          <Button variant="secondary" onClick={toggleIsOpenModal}>
            {t("footer.button")}
          </Button>
        </div>
        <div className="footer__social-networks">
          {socialNetworks.map(({ icon: Icon, link }) => (
            <a key={link} href={link} className="footer__social-network">
              <Icon />
            </a>
          ))}
        </div>
      </div>
      <div className="footer__information">
        <div className="footer__contacts-desktop footer__contacts--text">
          <a href={`tel:${contacts.phone}`} className="footer__contact-phone">
            {contacts.phone}
          </a>
          <a
            href={`mailto:${contacts.email}`}
            className="footer__contact-email"
          >
            {contacts.email}
          </a>
        </div>
        <div className="footer__agreements description-text">
          {t("footer.agreements")}
        </div>
      </div>
    </footer>
  );
};
