import React, { FC, PropsWithChildren } from "react";
import { IButton } from "./types";
import "./styles.scss";

export const Button: FC<PropsWithChildren<IButton>> = ({
  variant = "primary",
  type = "button",
  isLoading = false,
  disabled = false,
  rightIcon = null,
  leftIcon = null,
  onClick,
  children,
  className = "",
  form,
}) => {
  return (
    <button
      form={form}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-variant={variant}
      data-is-loading={isLoading}
      className={`button ${className}`}
    >
      {isLoading ? (
        <div className="button__loader" />
      ) : (
        <>
          {leftIcon && <div className="button__left-icon">{leftIcon}</div>}
          {children}
          {rightIcon && <div className="button__right-icon">{rightIcon}</div>}
        </>
      )}
    </button>
  );
};
