import { IHeaderLinks } from "./types";
import i18next from "i18next";

export const headerLinks: IHeaderLinks[] = [
  {
    title: i18next.t("header.about"),
    linkTo: "about",
  },
  {
    title: i18next.t("header.advantages"),
    linkTo: "functionalCapabilities",
  },
  {
    title: i18next.t("header.features"),
    linkTo: "features",
  },
  {
    title: i18next.t("header.faq"),
    linkTo: "faq",
  },
  {
    title: i18next.t("header.contacts"),
    linkTo: "contacts",
  },
];
