import { IConfigItem } from "../types";

export const tabs: IConfigItem[] = [
  {
    id: "introduction",
    labelKey: "faq.tabs.introduction",
  },
  {
    id: "security",
    labelKey: "faq.tabs.security",
  },
  {
    id: "registration",
    labelKey: "faq.tabs.registration",
  },
  {
    id: "buyCrypto",
    labelKey: "faq.tabs.buyCrypto",
  },
  {
    id: "sendFunds",
    labelKey: "faq.tabs.sendFunds",
  },
];
