import React, { FC, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { FeedbackContext } from "../../context";
import { Button, Input } from "shared/ui";
import "./styles.scss";

export const MainBanner: FC = () => {
  const { t } = useTranslation();
  const { email, changeEmail, error, joinToWaitList, isPending } =
    useContext(FeedbackContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    joinToWaitList();
  };

  return (
    <div className="main__banner" id="about">
      <form onSubmit={handleSubmit} className="main__banner-content">
        <h1 className="main__banner-title">
          <Trans i18nKey="mainBanner.title" components={[<span />, <span />]} />
        </h1>
        <p className="regular-text main__banner-description">
          {t("mainBanner.description")}
        </p>
        <Input
          disabled={isPending}
          className="main__banner-input__container"
          value={email}
          onChange={changeEmail}
          placeholder={t("mainBanner.emailLabel")}
          rightButton={
            <Button
              type="submit"
              isLoading={isPending}
              disabled={Boolean(error)}
              className="main__banner-button"
            >
              {t("mainBanner.joinButton")}
            </Button>
          }
          errorMessage={error}
        />
      </form>
      <StaticImage
        src="./assets/mainBannerMobile.webp"
        alt="app-view"
        objectFit="contain"
        className="main__banner-image__mobile"
      />
      <StaticImage
        src="./assets/mainBannerSmallTablet.webp"
        alt="app-view"
        objectFit="contain"
        className="main__banner-image__smallTablet"
      />
      <StaticImage
        src="./assets/mainBannerTablet.webp"
        alt="app-view"
        objectFit="contain"
        className="main__banner-image__tablet"
      />
      <StaticImage
        src="./assets/mainBannerLargeDesktop.webp"
        alt="app-view"
        objectFit="contain"
        className="main__banner-image__largeDesktop"
      />
    </div>
  );
};
