import React, { FC } from "react";
import { TileTextBlock } from "shared/ui";
import "./styles.scss";

export const SupportTile: FC = () => (
  <div className="support__tile">
    <TileTextBlock
      pointKey="functionalCapabilities.support.point"
      titleKey="functionalCapabilities.support.title"
      descriptionKey="functionalCapabilities.support.description"
    />
  </div>
);
