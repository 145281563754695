import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { tabs } from "./config";
import { Tab } from "shared/ui/Tab";
import { Point } from "shared/ui";
import "./styles.scss";
import { TConfigIdType } from "./types";
import { Question } from "../../shared/ui/Question";

export const FAQ: FC = () => {
  const { t } = useTranslation();
  const [currentId, setCurrentTab] = useState<TConfigIdType>(tabs[0].id);

  return (
    <div className="faq" id="faq">
      <Point pointKey="faq.point" />
      <h2 className="faq-title">{t("faq.title")}</h2>
      <div className="faq-content">
        <div className="faq-tabs__wrapper">
          <div className="faq-tabs__shadow" />
          <div className="faq-tabs">
            {tabs.map((tab) => (
              <Tab
                className="faq-tab"
                key={tab.id}
                {...tab}
                currentId={currentId}
                onClick={setCurrentTab}
              />
            ))}
          </div>
        </div>
        <div className="faq-questions">
          {t(`faq.${currentId}`, { returnObjects: true }).map((item) => (
            <Question key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};
