import React, { FC } from "react";
import { IPoint } from "./types";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export const Point: FC<IPoint> = ({
  pointKey,
  type = "primary",
  className = "",
}) => {
  const { t } = useTranslation();
  return (
    <p className={`tags-text point ${className}`} data-type={type}>
      {t(pointKey)}
    </p>
  );
};
