import { ReactComponent as Facebook } from "shared/assets/svg/facebook.svg";
import { ReactComponent as Whatsapp } from "shared/assets/svg/whatsapp.svg";
import { ReactComponent as Telegram } from "shared/assets/svg/telegram.svg";
import { ReactComponent as Instagram } from "shared/assets/svg/instagram.svg";

export const socialNetworks = [
  {
    icon: Facebook,
    link: "1",
  },
  {
    icon: Whatsapp,
    link: "2",
  },
  {
    icon: Telegram,
    link: "3",
  },
  {
    icon: Instagram,
    link: "4",
  },
];

export const contacts = {
  email: "hello@1ndex.io",
  phone: "+7 991 118 04 05",
};
