import React, { FC, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { FeedbackContext } from "../context";
import { emailRegex, EMAIL_MAX_LENGTH, ERoutes } from "shared/config";
import { useSendEmail } from "shared/lib";
import { navigate } from "gatsby";
import { PageTemplate } from "../components";

const Context: FC<PropsWithChildren> = ({ children, location }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string>("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { isPending, sendEmail, resetSendEmail } = useSendEmail();

  const changeEmail = (email: string) => {
    if (email && !emailRegex.test(email)) setError(t("validation.email"));
    else if (email.length > EMAIL_MAX_LENGTH)
      setError(
        t("validation.maxLength", {
          count: EMAIL_MAX_LENGTH,
        })
      );
    else setError("");

    setEmail(email);
  };

  const dropEmail = () => {
    setEmail("");
    setError("");
  };

  const toggleIsOpenModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  const joinToWaitList = () => {
    let errorKey;
    if (!email) errorKey = t("validation.required");

    if (errorKey) {
      setError(errorKey);
      return;
    }

    if (error) return;

    sendEmail({
      email,
      name: "JOIN_WAITLIST",
    }).finally(() => {
      dropEmail();
      resetSendEmail();
      navigate(ERoutes.thankYou);
    });
  };

  return (
    <FeedbackContext.Provider
      value={{
        email,
        changeEmail,
        dropEmail,
        error,
        toggleIsOpenModal,
        isOpenModal,
        isPending,
        joinToWaitList,
      }}
    >
      <PageTemplate pathname={location.pathname}>{children}</PageTemplate>
    </FeedbackContext.Provider>
  );
};

export default Context;
