import { createContext } from "react";

export const FeedbackContext = createContext({
  email: "",
  changeEmail: (email: string) => {},
  error: "",
  dropEmail: () => {},
  isOpenModal: false,
  toggleIsOpenModal: () => {},
  joinToWaitList: () => {},
  isPending: false,
});
